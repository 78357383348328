import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import OrderItem from "../OrderItem/orderItem";
import emptyCart from "../../images/emptycart.png";
import Header from "../Header/header";
import { useNavigate } from "react-router-dom";
import "./history.css";

const History = () => {
  const { userLogged } = useSelector((state) => state.user);
  const { userData } = useSelector((state) => state.user);
  const { orders } = userData;
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate("/login");
  };

  return (
    <div>
      <Header />
      {!userLogged ? (
        <>
          <h1 className="top1">Login to see your orders</h1>
          <div className="button-cont">
            <button className="button3" onClick={handleLoginClick}>
              Login
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="orders-container top ">
            <h1 className="head">MY ORDER HISTORY ({orders.length} Items)</h1>
            {orders.length !== 0 ? (
              <ul className="orders-container">
                {orders.map((eachOrder, index) => (
                  <OrderItem orderData={eachOrder} key={index} />
                ))}
              </ul>
            ) : (
              <div className="empty-orders">
                <img src={emptyCart} alt="empty cart" className="empty-cart" />
                <h1 className="empty-cart-h">You haven't placed an Order.</h1>
                <p className="empty-cart-p">Lets place some orders.</p>
                <Link to="/">
                  <button type="button" className="empty-cart-btn">
                    GO TO SHOP
                  </button>
                </Link>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default History;
